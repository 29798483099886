import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89')
];

export const server_loads = [0,2,4,6,8,9,10,11,12];

export const dictionary = {
		"/": [~18],
		"/admin": [~19,[2]],
		"/admin/audit-log": [~23,[2]],
		"/admin/labs": [~24,[2]],
		"/admin/materials": [~25,[2]],
		"/admin/permissions": [~26,[2]],
		"/admin/[distChannel]/materials": [~20,[2]],
		"/admin/[distChannel]/materials/[code]": [~21,[2]],
		"/admin/[distChannel]/prices": [~22,[2]],
		"/api/orders/[orderId]/[eye]/lens/profile": [~27],
		"/api/v1/docs": [~28],
		"/billing": [~29,[3]],
		"/billing/[distChannel]": [~30,[3]],
		"/billing/[distChannel]/orders": [~31,[3]],
		"/billing/[distChannel]/orders/[orderId]": [~32,[3]],
		"/clinic": [~33,[4]],
		"/clinic/admin": [~34,[4]],
		"/clinic/orders": [~35,[4]],
		"/clinic/orders/[orderId]": [~36,[4]],
		"/clinic/orders/[orderId]/reorder": [~38,[4]],
		"/clinic/orders/[orderId]/[eye]/lens/update": [~37,[4]],
		"/distributor": [~39,[5]],
		"/distributor/[distChannel]": [~40,[5,6]],
		"/distributor/[distChannel]/calculators": [~41,[5,6]],
		"/distributor/[distChannel]/calculators/practices": [~42,[5,6]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]": [~43,[5,6]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/forge": [~44,[5,6,7]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/scleral/step1": [~45,[5,6,8]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/scleral/step2": [~46,[5,6,8]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/scleral/step3": [~47,[5,6,8]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/scleral/step4": [~48,[5,6,8]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/scleral/step5": [~49,[5,6,8]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/scleral/step6": [~50,[5,6,8]],
		"/distributor/[distChannel]/diagnostic": [~51,[5,6]],
		"/distributor/[distChannel]/labels": [~52,[5,6]],
		"/distributor/[distChannel]/orders": [~53,[5,6]],
		"/distributor/[distChannel]/orders/create": [~58,[5,6]],
		"/distributor/[distChannel]/orders/create/practices": [~59,[5,6]],
		"/distributor/[distChannel]/orders/create/practices/[practiceId]": [~60,[5,6]],
		"/distributor/[distChannel]/orders/[orderId]": [~54,[5,6]],
		"/distributor/[distChannel]/orders/[orderId]/new": [~57,[5,6]],
		"/distributor/[distChannel]/orders/[orderId]/[eye]/lens/spec/[rowNumber]": [~55,[5,6]],
		"/distributor/[distChannel]/orders/[orderId]/[eye]/lens/update": [~56,[5,6]],
		"/distributor/[distChannel]/practices": [~61,[5,6]],
		"/distributor/[distChannel]/practices/[practiceId]": [~62,[5,6]],
		"/kb": [~63,[9]],
		"/kb/search": [~65,[9]],
		"/kb/[slug]": [~64,[9,10]],
		"/lab": [~66],
		"/lab/[labCode]": [67,[11]],
		"/lab/[labCode]/checklist": [~75,[11]],
		"/lab/[labCode]/clear_submit_bar_codes": [~76,[11]],
		"/lab/[labCode]/errored-orders": [~77,[11]],
		"/lab/[labCode]/lens/material": [~78,[11]],
		"/lab/[labCode]/print/forms": [~79,[11]],
		"/lab/[labCode]/qc": [~80,[11]],
		"/lab/[labCode]/[orderId]/diagnosticSet/qc": [~72,[11]],
		"/lab/[labCode]/[orderId]/print/forms": [~73,[11]],
		"/lab/[labCode]/[orderId]/qc": [~74,[11]],
		"/lab/[labCode]/[orderId]/[eye]/lens/update": [~68,[11]],
		"/lab/[labCode]/[orderId]/[eye]/qc": [~69,[11]],
		"/lab/[labCode]/[orderId]/[eye]/schematic": [~70,[11,12]],
		"/lab/[labCode]/[orderId]/[eye]/schematic/[revision]": [~71,[11,12]],
		"/login": [81,[13]],
		"/orders/[orderId]/lens/[eye]/profile": [~82],
		"/tools": [~83,[14]],
		"/tools/rigid-lens-bvp": [~84,[14,15]],
		"/tools/soft-toric-bvp": [~85,[14,16]],
		"/troubleshoot": [~86,[17]],
		"/troubleshoot/orders": [~87,[17]],
		"/v1/kb": [~88],
		"/v1/kb/[slug]": [~89]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';